@import './style/normalize';
@import './style/flexboxgrid';
@import './style/fonts';
@import './style/var';
@import './style//colors';
@import './style/mixin';

body {
  font-family: $font-base;
  font-size: 0.12rem;
}
.safe-area {
  padding: 0.16rem;
  box-sizing: border-box;
}
