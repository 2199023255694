@import '../../assets/style/colors';
@import '../../assets//style/var';
@import '../../assets//style/mixin';
.list-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  .list-empty {
    text-align: center;
    img {
      width: 1.6rem;
      height: 1.6rem;
    }
    h2 {
      @include openSansSemiBold();
      font-size: 0.18rem;
    }
    p {
      font-size: 0.14rem;
      color: $soft-grey;
    }
  }
  .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.12rem 0;
    box-sizing: border-box;
    border-bottom: 1px solid $very-light-grey;
    .icon {
      flex: 0 0 0.28rem;
      width: 0.28rem;
      height: 0.28rem;
      border: 1px solid $very-light-grey;
      box-sizing: border-box;
      padding: 0.04rem;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      padding-left: 0.16rem;
      font-size: 0.16rem;
      color: $black-two;
      flex: 1 1 auto;
    }
    .navigation {
      flex: 0 0 auto;
      width: 0.16rem;
      height: 0.16rem;
      img {
        width: inherit;
        height: inherit;
      }
    }
  }
}
