@import '../../assets/style/colors';
@import '../../assets//style/var';
.error-general {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 0;
  .error-container {
    margin: auto;
    max-width: 3rem;
    padding: 0 0.2rem;
    img {
      width: 1.6rem;
      height: 1.6rem;
    }
    .error-title {
      font-weight: $font-w-semi-bold;
      font-family: $font-second;
      font-size: 0.2rem;
    }
    .error-desc {
      font-size: 0.16rem;
      line-height: 1.5;
      color: $soft-grey;
    }
  }
  &.on {
    opacity: 1;
    z-index: 9998;
  }
  .error-footer {
    width: 100%;
    position: absolute;
    bottom: 0.08rem;
  }
  .error-action {
    border-top: 1px solid $very-light-grey;
    padding: 0.08rem 0.16rem 0 0.16rem;
    box-sizing: border-box;
  }
  .version-info {
    font-size: 0.08rem;
    opacity: 0.5;
    margin-top: 0.02rem;
    margin-bottom: 0.1rem;
  }
}
