@import '../../assets/style/colors';
@import '../../assets//style/var';
.biller-page {
  line-height: 1.5;
  display: flex;
  flex-flow: column;
  width: 100%;
  .biller-banner {
    width: 100%;
    height: 1rem;
    margin-bottom: 0.08rem;
    img {
      width: 100%;
      height: inherit;
    }
  }
  .biller-content {
    padding: 0.16rem;
    box-sizing: border-box;
    .biller-form {
      padding-bottom: 0.08rem;
      margin-bottom: 0.08rem;
      border-bottom: 1px solid $very-light-grey;
      box-sizing: border-box;
      .form-btn {
        margin-top: 0.24rem;
      }
    }
    
  }
}
