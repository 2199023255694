@import '../../assets/style/colors';
@import '../../assets/style/var';
@import '../../assets/style/mixin';
.select-input {
  &.error {
    .form-input {
      color: $error-color;
      input {
        border-radius: 4px;
        outline: none !important;
        border: 2px solid $error-color;
        &:focus {
          outline: none !important;
          border: 2px solid $error-color;
        }
      }
    }
  }
  .form-title {
    display: flex;
    align-items: center;
    .text-title {
      text-transform: uppercase;
      color: $soft-grey;
      @include openSansSemiBold();
    }
    .icon-title {
      margin-left: 0.06rem;
      width: 0.16rem;
      height: 0.16rem;
      img {
        width: inherit;
        height: inherit;
      }
    }
  }
  .form-input {
    width: 100%;
    margin: 0.06rem 0;
    box-sizing: border-box;
    position: relative;
    .close {
      position: absolute;
      width: 0.16rem;
      height: 0.16rem;
      top: 0.1rem;
      bottom: 0;
      right: 0.1rem;
      opacity: 0;
      transition: 0.2s ease-in-out;
      background-color: white;
      z-index: 2;
      &.active {
        opacity: 1;
      }
      img {
        width: inherit;
        height: inherit;
      }
    }
    &::after {
      position: absolute;
      background-image: url('../../assets//icons/arrow-icon.svg');
      background-repeat: no-repeat;
      background-size: cover;
      top: 0.09rem;
      right: 0.14rem;
      width: 0.08rem;
      height: 0.16rem;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      z-index: 1;
      content: '';
    }
    input {
      width: inherit;
      border: 2px solid $very-light-grey;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: $white;
      font-size: 0.16rem;
      font-weight: $font-w-normal;
      padding: 0.08rem;
      &:focus {
        outline: none !important;
        border: 2px solid $focus-color;
      }
    }
    .select-list-outer {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: transparent;
      z-index: 1;
    }
    .select-list {
      position: absolute;
      width: 100%;
      color: $black-two;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      flex-flow: column;
      border-radius: 4px;
      background-color: $white;
      z-index: 2;
      max-height: 0.95rem;
      overflow: auto;
      .item {
        padding: 0.08rem;
        font-size: 0.14rem;
        // border-bottom: 1px solid $very-light-grey;
        // &:first-child {
        //   padding-top: 0.08rem;
        // }
        // &:last-child {
        //   padding-bottom: 0.08rem;
        //   border-bottom: none;
        // }
      }
    }
    .notice-message {
      display: inline-block;
      font-size: 0.12rem;
      margin-top: 0.04rem;
    }
  }
}
