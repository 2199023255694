@import '../../assets/style/colors';
@import '../../assets//style/var';
.search-input {
  position: relative;
  .icon {
    position: absolute;
    width: 0.16rem;
    height: 0.16rem;
    top: 0.1rem;
    left: 0.1rem;
    bottom: 0;
    right: 0;
    img {
      width: inherit;
      height: inherit;
    }
  }
  .close {
    position: absolute;
    width: 0.16rem;
    height: 0.16rem;
    top: 0.1rem;
    bottom: 0;
    right: 0.1rem;
    opacity: 0;
    transition: 0.2s ease-in-out;
    &.active {
      opacity: 1;
    }
    img {
      width: inherit;
      height: inherit;
    }
  }
  input {
    width: 100%;
    height: 0.36rem;
    border: 2px solid $very-light-grey;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: $white;
    font-size: 0.16rem;
    font-weight: $font-w-normal;
    color: $black-two;
    padding-left: 0.35rem;
    &:focus {
      outline: none !important;
      border: 2px solid $focus-color;
    }
    &:not(:placeholder-shown) {
      .close {
        opacity: 1;
      }
    }
  }
}
