@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./fonts/SFProText-Regular.ttf');
}
