@import '../../assets//style/mixin';
@import '../../assets/style/colors';
@import '../../assets//style/var';
.bottom-sheet {
  position: relative;
  &.open {
    .background-layer {
      z-index: 998;
      opacity: 1;
    }
    .bottom-sheet-wrapper {
      z-index: 999;
      opacity: 1;
      bottom: 0;
    }
  }
  .background-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.48);
    opacity: 0;
    transition: 0.1s ease;
  }
  .bottom-sheet-wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: -0.5rem;
    z-index: -1;
    opacity: 0;
    background-color: $white;
    border-top-left-radius: 0.16rem;
    border-top-right-radius: 0.16rem;
    transition: 0.1s ease;
    .drag-indicator {
      background-color: $light-grey;
      width: 0.6rem;
      height: 0.04rem;
      border-radius: 0.02rem;
      margin: 0.08rem auto;
      content: '';
    }
    .bottom-sheet-content {
      max-height: 6rem;
      overflow: auto;
      .loader-bottom-sheet {
        width: 100%;
        height: 3rem;
        display: flex;
        .spinner {
          width: 0.5rem;
          height: 0.5rem;
          background-image: url('../../assets/images/loadingcenter.png');
          background-size: cover;
          margin: auto;
          content: '';
        }
        .rotate {
          width: 0.48rem;
          padding: 0.01rem;
          animation: rotation 1s infinite linear;
        }
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }

          to {
            transform: rotate(359deg);
          }
        }
      }
      &.pad {
        padding: 0.12rem;
      }
      .bottom-sheet-general {
        padding: 0.16rem;
        text-align: center;
        .notifier-img {
          width: 1.6rem;
        }
        h2 {
          @include openSansSemiBold();
          font-size: 0.2rem;
          margin-bottom: 0;
        }
        p {
          margin-top: 0.06rem;
          margin-bottom: 0.16rem;
          font-size: 0.16rem;
          color: $soft-grey;
        }
      }
    }
  }
}
