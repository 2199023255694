$sub-color: grey;
$suffix-color: #c0c0c0;
$main-color: black;
$black-two: #313131;
$focus-color: #fb8b01;
$error-color: #ff5d55;
$white: #fff;
$white-two: #e9f5fe;
$dana-blue: #108ee9;
$dana-blue-light: #2196f3;
$soft-grey: #727272;
$light-grey: #e3e3e3;
$idle-grey: #a4a4a4;
$very-light-grey: #d1d1d1;
$very-light-two: #bfbfbf;
$grey-light-three: #e7e7e7;
$green-hornet: #00ce64;
$red-clear: #fa6060;
$white-grey: #f5f5f5;
$bubble-grey: #b6b6b6;
$orange-white: #FEF0DE;
