@font-face {
	font-family: 'Open Sans';
	src: url('../../../src/assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
	font-family: 'Open Sans';
	src: url('../../../src/assets/fonts/OpenSans-Bold.ttf');
	font-weight: bold;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../../../src/assets/fonts/OpenSans-SemiBold.ttf');
	font-weight: 600;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../../../src/assets/fonts/OpenSans-Italic.ttf');
	font-style: italic;
}

.preview-tnc {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 1.6rem 1.6rem;
	width: 100%;
	font-size: 2rem;
	overflow: auto;
	overflow-x: hidden;
	box-sizing: border-box;
	width: 100%;
}

.preview-tnc .content-tnc {
	display: inline-block;
	word-wrap: break-word;
	width: inherit;
}

.preview-tnc .content-tnc p{
	font-size: 2rem;
	line-height: 1.5;
}

.preview-tnc ul {
	padding: 0;
	margin-left: 2rem;
}

.preview-tnc ul li {
	padding-left: 1.2rem;
}

.preview-tnc ol {
	margin-left: 1.6rem;
	list-style-type: lower-roman !important;
}

.preview-tnc ol li {
	padding-left: 1.6rem;
}
