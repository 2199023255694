@import '../../assets/style/mixin';
@import '../../assets/style/colors';
@import '../../assets/style/var';

.bottom-sheet-confirmation {
  position: relative;
  &.open {
    .background-layer {
      z-index: 998;
      opacity: 1;
    }
    .bottom-sheet-wrapper {
      z-index: 999;
      opacity: 1;
      bottom: 0;
    }
  }
  .background-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.48);
    opacity: 0;
    transition: 0.1s ease;
  }
  .bottom-sheet-wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: -0.5rem;
    z-index: -1;
    opacity: 0;
    background-color: $white;
    border-top-left-radius: 0.16rem;
    border-top-right-radius: 0.16rem;
    transition: 0.1s ease;
    .bottom-sheet-loading {
      // TODO LATER
    }
    .bsc-head {
      border-bottom: 0.08rem solid $white-grey;
      .head-top {
        padding: 0.16rem;
        display: flex;
        .icon-close {
          padding-top: 0.04rem;
          width: 0.11rem;
          height: 0.11rem;
          img {
            width: inherit;
            height: inherit;
          }
        }
        .bsc-title {
          @include openSansSemiBold();
          padding-left: 0.12rem;
          color: $black-two;
          font-size: 0.14rem;
        }
      }
    }
    .head-low {
      display: flex;
      align-items: center;
      padding: 0 0.16rem 0.16rem 0.16rem;
      .icon-biller {
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid $very-light-grey;
        padding: 0.06rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .biller-info {
        display: flex;
        flex-flow: column;
        padding-left: 0.12rem;
        text-align: left;
        .biller-title {
          @include openSansSemiBold();
          color: $black-two;
          font-size: 0.16rem;
        }
        .biller-desc {
          font-size: 0.12rem;
          color: $soft-grey;
        }
      }
    }
    .bottom-sheet-content {
      max-height: 950px;
      overflow: auto;
      &.pad {
        padding: 0.12rem;
      }
      .bsc-list-confirm {
        display: flex;
        flex-flow: column;
        padding: 0.1rem 0;
        .item {
          display: flex;
          justify-content: space-between;
          line-height: 2;
          padding: 0.03rem 0.12rem;
          &.single-devider {
            border-bottom: 0.02rem solid $white-grey;
          }
          &.double-devider {
            border-bottom: 0.08rem solid $white-grey;
          }
          .item-name {
            @include openSansSemiBold();
            color: $soft-grey;
            font-size: 0.16rem;
            text-align: left;
            line-height: 1.5;
            white-space: nowrap;
          }
          .item-value {
            font-size: 0.16rem;
            color: $black-two;
            text-align: right;
            line-height: 1.5;
            &.bold {
              font-weight: bold;
            }
          }
        }
      }
      .bsc-btn {
        padding: 0 0.12rem 0.12rem 0.12rem;
      }
    }
  }

	.bottom-sheet-agreement {
		padding-bottom: 0.16rem;
		display: block;
		text-align: left;
	}

	.bottom-sheet-agreement-checkbox {
		width: 0.16rem;
		height: 0.16rem;
		margin-right: 0.1rem;
		vertical-align: text-bottom;
		accent-color: $dana-blue;
	}

	.bottom-sheet-agreement-desc {
		font-size: 0.12rem;
		margin-right: 0.04rem;
	}

	.bottom-sheet-agreement-link {
		font-size: 0.12rem;
		color: $dana-blue;
		text-decoration: none;
		background: none;
		border: none;
	}
}
