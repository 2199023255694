@import '../../assets//style/mixin';
@import '../../assets/style/colors';
@import '../../assets//style/var';
.button {
  border-width: 0.01rem;
  border-style: solid;
  padding: 0.08rem 0.16rem;
  border-radius: 0.05rem;
  outline: 0;
  background: $white;
  font-family: $font-second;
  font-size: 0.16rem;
  text-decoration: none;
  border: 0.01rem solid $dana-blue;
  @include xSmall() {
    padding: 0.06rem;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.primary {
    color: $white;
    background: $dana-blue;
    &.inactive {
      color: $white;
      background: $very-light-two;
      border: 0.01rem solid $very-light-two;
    }
  }
  &.invert {
    color: $dana-blue;
    background: $white;
  }
  &.outline {
    color: $dana-blue;
    &.inactive {
      color: $very-light-two;
      border: 0.01rem solid $very-light-two;
    }
  }
  &.full {
    width: 100%;
  }
  &.no-margin {
    margin: 0rem;
  }
  &.bold {
    font-weight: 600;
  }
  &.small {
    font-size: 0.12rem;
  }
  &.big {
    font-size: 0.16rem;
    font-weight: 600;
    min-height: 0.45rem;
    @include xSmall() {
      font-size: 0.15;
    }
  }
  &.nowrap {
    white-space: nowrap;
  }
  &.denom {
    font-size: 0.25rem;
    width: 0.37rem;
    height: 0.2rem;
    padding-bottom: 0.35rem;
    padding-top: 0;
  }
}
