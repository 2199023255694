.services-page {
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .search-service {
  }
  .list-view-service {
    flex: auto;
    .list-view {
      .list-item {
        &:first-child {
          margin-top: 0.16rem;
        }
      }
      .list-empty {
        margin-top: 1rem;
      }
    }
  }
}
