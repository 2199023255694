@import '../../assets/style/colors';
@import '../../assets//style/var';
@import '../../assets//style//mixin';
.sticky-note {
  padding: 0.16rem;
  background-color: $orange-white;
  border: 1px solid $focus-color;
  border-radius: 0.04rem;
  .sticky-title {
    @include openSansSemiBold();
    font-size: 0.14rem;
    position: relative;
    display: inline-block;
    color: $black-two;
    &::after {
      content: '';
      background-image: url('../../assets/icons/sticky-info-filled.svg');
      background-size: cover;
      background-repeat: no-repeat;
      width: 0.16rem;
      height: 0.16rem;
      top: 0.03rem;
      right: -0.2rem;
      position: absolute;
      display: inline-block;
    }
    margin-bottom: 0.06rem;
  }
  .sticky-content {
    color: $soft-grey;
    font-size: 0.12rem;
    p {
      margin: 0;
    }
    ul {
      margin: 0.06rem 0;
    }
  }
}
