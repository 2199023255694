@import '../../assets/style/colors';
@import '../../assets/style/var';
@import '../../assets/style/mixin';
.text-input {
  &.error {
    .form-input {
      color: $error-color;
      input {
        border-radius: 4px;
        outline: none !important;
        border: 2px solid $error-color;
        &:focus {
          outline: none !important;
          border: 2px solid $error-color;
        }
      }
      
    }
  }
  .form-title {
    display: flex;
    align-items: center;
    .text-title {
      text-transform: uppercase;
      color: $soft-grey;
      @include openSansSemiBold();
    }
    .icon-title {
      margin-left: 0.06rem;
      width: 0.16rem;
      height: 0.16rem;
      img {
        width: inherit;
        height: inherit;
      }
    }
    
  }
  .form-input {
    width: 100%;
    position: relative;
    margin: 0.06rem 0;
    box-sizing: border-box;
    .close {
      position: absolute;
      width: 0.16rem;
      height: 0.16rem;
      top: 0.1rem;
      bottom: 0;
      right: 0.1rem;
      opacity: 0;
      transition: 0.2s ease-in-out;
      &.active {
        opacity: 1;
      }
      img {
        width: inherit;
        height: inherit;
      }
    }
    input {
      width: inherit;
      border: 2px solid $very-light-grey;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: $white;
      font-size: 0.16rem;
      font-weight: $font-w-normal;
      padding: 0.08rem;
      &:focus {
        outline: none !important;
        border: 2px solid $focus-color;
      }
    }
    .notice-message {
      display: inline-block;
      font-size: 0.12rem;
      margin-top: 0.04rem;
    }
  }
  
}
