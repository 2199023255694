@import '../../assets/style/colors';
@import '../../assets//style/var';
.loading-mask {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  opacity: 0;
  transition-duration: 0.2s;
  &.on {
    opacity: 1;
    z-index: 9999;
  }
}

.spinner {
  width: 0.5rem;
  height: 0.5rem;
  background-image: url('../../assets/images/loadingcenter.png');
  background-size: cover;
  margin: auto;
  content: '';
}

.rotate {
  width: 0.48rem;
  padding: 0.01rem;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
